<template>
  <div>
    {{ discount.codigo_material }}
    <span v-for="(item, i) in validateDiscount" :key="i.id">
      <v-tooltip
        bottom
        v-if="
          discount[item.id] != 0 && item.id != 'MVGR5_POR' && item.id != 'ZK14'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"
            ><img
              :src="`/img/discount_icons/${item.id}.svg`"
              style="width: 50px"
              alt=""
          /></span> </template
        ><span
          >{{ item.description }} {{ seePorcent(discount[item.id]) }}%</span
        >
      </v-tooltip>
      <v-tooltip bottom v-if="discount[item.id] != 0 && item.id == 'MVGR5_POR'">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"
            ><img
              :src="`/img/discount_icons/${discount.MVGR5}.svg`"
              style="width: 50px"
              alt=""
            />
          </span> </template
        ><span>{{ item.description }} {{ discount[item.id] }} %</span>
      </v-tooltip>
      <v-tooltip bottom v-if="discount[item.id] != 0 && item.id == 'ZK14'">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="discountString(discount[item.id])"
            color="transparent"
            style="font-weight: bold"
            :offset-x="counterPercent(discount[item.id]) > 2 ? '50px' : '34px'"
            :offset-y="counterPercent(discount[item.id]) > 2 ? '33px' : '26px'"
          >
            <span v-bind="attrs" v-on="on"
              ><img
                :src="`/img/discount_icons/${item.id}.svg`"
                :style="
                  counterPercent(discount[item.id]) > 2
                    ? 'width: 47px'
                    : 'width: 33px'
                "
                alt=""
              />
            </span> </v-badge></template
        ><span>{{ item.description }} {{discountString(discount[item.id])}}</span>
      </v-tooltip>
    </span>
  </div>
</template>
<script>
export default {
  name: "SpecialDiscountIcon",
  props: {
    discount: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    validateDiscount: [
      { id: "ZKVL", description: "Volumen" },
      { id: "ZKRF", description: "Familia" },
      { id: "ZKRG", description: "Renglonaje" },
      { id: "ZKCM", description: "Comercial" },
      { id: "ZK69", description: "Especial Familia" },
      { id: "PNC", description: "Producto Nuevo" },
      { id: "KPRK", description: "Producto Combo" },
      { id: "ZKDE", description: "" },
      { id: "ZK25", description: "Especial Renglón" },
      { id: "ZK71", description: "Descuento" },
      { id: "ZKSP", description: "Descuento Especial" },
      { id: "ZK14", description: "Promoción" },
      { id: "MVGR5_POR", description: "Factor" },
    ],
  }),

  methods: {
    discountString(value) {
      // This is because it does not display 0 when is INT
      const valuePercent = parseFloat(value * 100);
      const result = valuePercent - Math.floor(valuePercent) !== 0;
      if (result) {
       const decimal = valuePercent.toFixed(2);
       return `${decimal}%`;
      } else {
        const decimal = valuePercent.toFixed(0);
        return `${decimal}%`;
      }
    },
    counterPercent(value) {
      
      const valuePercent = parseFloat(value * 100);
      const result = valuePercent - Math.floor(valuePercent) !== 0;

      if (result) {
        const decimal = valuePercent.toFixed(2);
        return decimal.length;
      } else {
        const decimal = valuePercent.toFixed(0);
        return decimal.length;
      }
    },
    seePorcent(value) {
      return parseFloat(value * 100).toFixed(2);
    },
  }
};
</script>
